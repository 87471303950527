import wrapWithProvider from "./src/templates/layouts/PageLayout"

export const onInitialClientRender = () => {
  setTimeout(() => {
    const style = document.getElementById("___loader").style
    style.opacity = 0
    setTimeout(() => {
      style.display = "none"
    }, 500)
  }, 1000)
}

export const wrapRootElement = wrapWithProvider
