import { CssBaseline, ThemeProvider } from "@material-ui/core"
import React, { FC, ReactNode } from "react"
import { Helmet } from "react-helmet"
import SimpleReactLightbox from "simple-react-lightbox"
import theme from "src/config/theme"

const PageLayout: FC<{ element: ReactNode }> = ({ element }) => {
  const metaImage = "https://blackblock.studio/seo-img.jpg"
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>BLACK BLOCK STUDIO</title>
        <meta name="og:title" content="BLACK BLOCK STUDIO" />
        <meta name="twitter:title" content="BLACK BLOCK STUDIO" />
        <meta
          name="description"
          content="Студия звукозаписи и репетиционная база. Мы собрали команду профессионалов для реализации ваших музыкальных идей. Лучшее соотношение цены и качества - наш главный принцип. Широкий круг творческих связей в музыкальном мире позволяет нам привлечь к работе специалистов любой сферы - позвоните нам, если Вам нужно что-то уникальное!"
        />
        <meta
          name="og:description"
          content="Студия звукозаписи и репетиционная база. Мы собрали команду профессионалов для реализации ваших музыкальных идей. Лучшее соотношение цены и качества - наш главный принцип. Широкий круг творческих связей в музыкальном мире позволяет нам привлечь к работе специалистов любой сферы - позвоните нам, если Вам нужно что-то уникальное!"
        />
        <meta
          name="twitter:description"
          content="Студия звукозаписи и репетиционная база. Мы собрали команду профессионалов для реализации ваших музыкальных идей. Лучшее соотношение цены и качества - наш главный принцип. Широкий круг творческих связей в музыкальном мире позволяет нам привлечь к работе специалистов любой сферы - позвоните нам, если Вам нужно что-то уникальное!"
        />
        <meta property="image" content={metaImage} />
        <meta property="og:image" content={metaImage} />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="og:type" content="website" />
        <meta name="theme-color" content="#121212" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="anonymous"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/favicon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <meta
          name="msapplication-TileImage"
          content="/favicon/ms-icon-144x144.png"
        />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <script src="//code.jivosite.com/widget/OkD89rVX9h" async />
      </Helmet>
      <CssBaseline />
      <SimpleReactLightbox>
        <div
          key="loader"
          id="___loader"
          style={{
            alignItems: "center",
            backgroundColor: "#121212",
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            transition: "all 500ms",
          }}
        >
          <img
            src="data:image/svg+xml,%3Csvg width='55' height='80' viewBox='0 0 55 80' xmlns='http://www.w3.org/2000/svg' fill='%23E42727'%3E%3Cg transform='matrix(1 0 0 -1 0 80)'%3E%3Crect width='10' height='20' rx='3'%3E%3Canimate attributeName='height' begin='0s' dur='4.3s' values='20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='15' width='10' height='80' rx='3'%3E%3Canimate attributeName='height' begin='0s' dur='2s' values='80;55;33;5;75;23;73;33;12;14;60;80' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='30' width='10' height='50' rx='3'%3E%3Canimate attributeName='height' begin='0s' dur='1.4s' values='50;34;78;23;56;23;34;76;80;54;21;50' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='45' width='10' height='30' rx='3'%3E%3Canimate attributeName='height' begin='0s' dur='2s' values='30;45;13;80;56;72;45;76;34;23;67;30' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3C/g%3E%3C/svg%3E"
            alt="loading spinner"
            width="150"
            height="150"
          />
        </div>
        {element}
      </SimpleReactLightbox>
    </ThemeProvider>
  )
}

export default PageLayout
