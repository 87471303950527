import { createTheme } from "@material-ui/core/styles"

const theme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#121212",
    },
    primary: {
      main: "#E42727",
      light: "#181818",
    },
    text: {
      secondary: "#ffffff99",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    h1: {
      fontSize: 36,
      "@media(min-width: 600px)": {
        fontSize: 48,
      },
      "@media(min-width: 960px)": {
        fontSize: 64,
      },
      "& span": {
        fontWeight: 700,
      },
    },
    h2: {
      fontSize: 32,
      "@media(min-width: 600px)": {
        fontSize: 42,
      },
      "@media(min-width: 960px)": {
        fontSize: 48,
      },
      "& span": {
        fontWeight: 700,
      },
    },
    h3: {
      fontSize: 24,
      "@media(min-width: 600px)": {
        fontSize: 30, // ??? need to change
      },
      "@media(min-width: 960px)": {
        fontSize: 32,
      },
      "@media(min-width: 1280px)": {
        fontSize: 36,
      },
      "& span": {
        fontWeight: 700,
      },
    },
    h4: {
      fontSize: 18,
      "@media(min-width: 600px)": {
        fontSize: 21,
      },
      "@media(min-width: 960px)": {
        fontSize: 24,
      },
      "& span": {
        fontWeight: 700,
      },
    },
    h5: {
      fontSize: 20,
      "@media(min-width: 600px)": {
        fontSize: 24,
      },
      "& span": {
        fontWeight: 700,
      },
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 18,
      "@media(min-width: 600px)": {
        fontSize: 24,
      },
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 16,
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      "@media(min-width: 600px)": {
        fontSize: 20,
      },
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        padding: "50px 24px",
        borderRadius: 0,
        "@media(min-width: 600px)": {
          padding: "50px 40px",
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#252525",
        boxShadow: "none",
      },
    },
  },
})

export default theme
